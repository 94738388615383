import logo from './logocolor.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome to sehgal tech
        </p>
       Visit our <a href='https://www.instagram.com/devmadesimple/' target="_blank" rel="noreferrer" >Instagram Channel</a> for more technical posts
       Visit our <a href='https://www.facebook.com/devmadesimple' target="_blank" rel="noreferrer" >Facebook Page</a> for more technical posts
       Visit our <a href='https://www.youtube.com/channel/UCVCTuszyIFFKepdubSl0cRw' target="_blank" rel="noreferrer" >Youtube Channel</a> for more technical posts
      </header>
    </div>
  );
}

export default App;
